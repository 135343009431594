import {Routes, Route, useNavigationType, useLocation} from "react-router-dom";
import React from "react";
import * as pages from "@pages";
import {ScreenId} from "@assets";

interface IRoute {
  path: ScreenId;
  element: JSX.Element;
}

const initRouteList: IRoute[] = [
  {
    path: ScreenId.HOME,
    element: <pages.HomePage />,
  },
  {
    path: ScreenId.COMPANY,
    element: <pages.CompanyPage />,
  },
  {
    path: ScreenId.STORY,
    element: <pages.StoryPage />,
  },
  {
    path: ScreenId.BOARD,
    element: <pages.BoardPage />,
  },
  {
    path: ScreenId.BOARD_DETAIL,
    element: <pages.BoardDetailPage />,
  },
  {
    path: ScreenId.MAP,
    element: <pages.MapPage />,
  },
  {
    path: ScreenId.RESERVATION,
    element: <pages.ReservationPage />,
  },
  {
    path: ScreenId.WEDDING,
    element: <pages.WeddingPage />,
  },
];

export default function WebRouteApp() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Routes>
      <Route
        path="/"
        element={(initRouteList || [])[0]?.element || <pages.ErrorPage />}
      />
      {initRouteList.map((_route, i) => (
        <Route key={i} path={_route.path} element={_route.element} />
      ))}
      <Route path="*" element={<pages.ErrorPage />} />
    </Routes>
  );
}
