import React from "react";
import styles from "./style.module.scss";
import {Content, Img, TopSection} from "@components";
import {Images, ScreenId} from "@assets";
import {useNav} from "@hook";
import {utils} from "@utils";

export default function ReservationPage() {
  const {navigate} = useNav();

  return (
    <div className={styles.container}>
      <TopSection
        title="RESERVATION"
        subTitle="상담예약"
        imgUrl={Images.home_04}
        menuList={[
          {
            title: "상담예약",
            onClick: () => navigate({url: ScreenId.RESERVATION}),
          },
          {title: "찾아오는 길", onClick: () => navigate({url: ScreenId.MAP})},
        ]}
      />
      <Content>
        <div className={styles.logoSection}>
          <Img src={Images.logoGray} />
          <div className={styles.title}>RESERVATION</div>
          <div className={styles.subTitle}>
            차별화된 컨셉으로 인생의 특별한 날을 더 아름답고 의미있게 만들어
            드립니다.
          </div>
          <div className={styles.telContainer}>
            고객센터 번호 010-4550-0700 <span>(월 ~일 9:00 ~ 19:00)</span>
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.item} onClick={utils.openKakao}>
            <div className={styles.subTitle}>카카오톡</div>
            <div className={styles.title}>상담채널</div>
            <div className={styles.div}></div>
            <div className={styles.content}>
              이곳을 클릭하면
              <br />
              상담채널로 이동합니다.
            </div>
          </div>
          <div className={styles.item} onClick={utils.openKakao}>
            <div className={styles.subTitle}>카카오톡 아이디</div>
            <div className={styles.title}>큐알코드 안내</div>
            <div className={styles.content} style={{marginTop: 16}}>
              <Img src={Images.kakaoQr} />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}
