import moment from "moment";

const cookies = {
  setCookie: ({
    name,
    value,
    expire = 7,
  }: {
    name: string;
    value: string;
    expire?: number;
  }) => {
    const date = moment().add(expire * 60 * 60 * 1000);
    const encodedValue = encodeURI(value);
    document.cookie = `${name}=${encodedValue};expires=${moment
      .utc(date)
      .valueOf()};path=/`;
  },

  getCookie: ({name}: {name: string}) => {
    const value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    if (value === null) {
      return null;
    }
    return decodeURI(value[2]);
  },

  deleteCookie: ({name}: {name: string}) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1999 00:00:10 GMT;path=/;";
  },
};

export default cookies;
