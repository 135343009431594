import {utils} from "@utils";
import React, {useState} from "react";
import styles from "./style.module.scss";
import {Images} from "@assets";
import {Modal} from "@components";

interface IProps {
  src?: string;
  className?: string | string[];
  isDetailModal?: boolean;
}

export default function Img({src, className, isDetailModal = false}: IProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classList = [
    styles.img,
    ...(Array.isArray(className) ? className : [className]),
  ].filter(utils.isDefined);

  function close() {
    setIsOpenModal(false);
  }

  if (!src) {
    return <></>;
  }

  return (
    <>
      <img
        src={src}
        onError={e => {
          e.currentTarget.src = Images.noImage;
        }}
        className={[
          styles.img,
          isDetailModal && styles.isCursor,
          ...classList,
        ].join(" ")}
        onClick={() => {
          if (isDetailModal) {
            setIsOpenModal(!isOpenModal);
          }
        }}
      />
      {!!isDetailModal && (
        <Modal
          backOpacity={70}
          visible={isOpenModal}
          close={close}
          className={styles.modalClass}
          containerClassName={styles.modalContainer}
          toWay="none">
          <div className={styles.modal}>
            <div className={styles.close} onClick={close}>
              X
            </div>
            <div className={styles.imgContainer}>
              <img src={src} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
