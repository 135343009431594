import React from "react";
import styles from "./style.module.scss";
import {Img} from "@components";
import {Images} from "@assets";

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Img src={Images.logoBottomWhite} />
        </div>
        <div className={styles.row}>
          <div className={styles.info}>
            <div className={styles.infoWrap}>
              <div>상담시간 : 월 ~ 일 09:00 ~ 19:00</div>
              <span className={styles.infoDiv}></span>
              <div>고객센터 : 010-4550-0700</div>
            </div>
            <div>
              토브웨딩 주소 : ENDEAVOR TOWER B1층 1044호 서울특별시 서초구
              서초대로74길 45 (서초동)
            </div>
            <div className={styles.infoWrap}>
              <div>사업자등록번호 : 646-13-02560</div>
              <span className={styles.infoDiv}></span>
              <div>대표자명 : 이준혁</div>
            </div>
            <div>Copyright © 2024 TOV WEDDING. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </div>
  );
}
