export enum ScreenId {
  HOME = "/",
  COMPANY = "/company",
  STORY = "/story",
  BOARD = "/board",
  BOARD_DETAIL = "/board-detail",
  RESERVATION = "/reservation",
  MAP = "/map",
  WEDDING = "/wedding",
}
