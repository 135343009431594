import _ from "lodash";
import React from "react";
import styles from "./style.module.scss";
import {Img} from "@components";
import {Images} from "@assets";
import {utils} from "@utils";
import Slider from "react-slick";

const imgArr = [
  Images.banner05,
  Images.banner01,
  Images.banner02,
  Images.banner03,
  Images.banner04,
  Images.banner06,
  Images.banner07,
  Images.banner08,
  Images.banner09,
];

export default function HomePage() {
  return (
    <div className={styles.container}>
      <div className={styles.bannerContainer}>
        <Slider
          autoplay
          autoplaySpeed={5000}
          dots={true}
          dotsClass={styles.bannerPaging}
          fade={true}
          customPaging={() => <div className={styles.dots} />}>
          {imgArr.map((imgUrl, index) => (
            <Img key={index} src={imgUrl} className={styles.banner} />
          ))}
        </Slider>
        <div className={styles.tovMainBackground}>
          <div>
            <div className={styles.tovMainBackgroundLogo}>
              <Img src={Images.logoWhite} />
            </div>
            <div className={styles.tovMainBackgroundSubTitle}>
              소중한 예식의 순간 <span>TOV wedding</span>
            </div>
            <div className={styles.tovMainBackgroundTitle}>
              최고의 경험으로 선사합니다
            </div>
            <div className={styles.tovMainBackgroundDiv}></div>
            <div className={styles.tovMainBackgroundBottomText}>
              꿈꿔왔던 해외웨딩을 더욱 찬란하게,{" "}
              <span>합리적인 가격으로 함께 합니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainSnsIcon}>
        <div className={styles.mainSnsIconContainer}>
          <div className={styles.mainSnsIconItem} onClick={utils.openYoutube}>
            <Img src={Images.youtube} />
          </div>
          <div className={styles.mainSnsIconItem} onClick={utils.openInstagram}>
            <Img src={Images.instagram} />
          </div>
          <div className={styles.mainSnsIconItem} onClick={utils.openKakao}>
            <Img src={Images.kakao} />
          </div>
        </div>
      </div>
    </div>
  );
}
