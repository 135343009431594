import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {useNav} from "@hook";
import {api} from "@utils";
import * as Datas from "@data";
import {Images, ScreenId} from "@assets";
import {
  Content,
  InputBox,
  Pagination,
  SelectBox,
  TopSection,
} from "@components";
import _ from "lodash";
import moment from "moment";

export default function BoardPage() {
  const {navigate} = useNav();
  const [listData, setListData] =
    useState<Datas.ResultPagingResponse<Datas.INotice[]>>();
  const [searchData, setSearchData] = useState<{
    date?: Datas.TSearchDate;
    type?: Datas.TSearchType;
    searchTxt?: string;
  }>({date: "", type: "title"});

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, data} = await api.v1.notice.getList({
        page,
        limit: 15,
        ...searchData,
      });
      if (success) {
        setListData(data);
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  function goToDetail({data}: {data: Datas.INotice}) {
    if (!data || !data.no) {
      return;
    }
    navigate({
      url: ScreenId.BOARD_DETAIL,
      state: {data},
      params: {no: data.no},
      scrollToTop: false,
    });
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={styles.container}>
      <TopSection
        title="TOV WEDDING"
        subTitle="토브웨딩 소식"
        imgUrl={Images.home_06}
        menuList={[
          {
            title: "토브웨딩 소식",
            onClick: () => navigate({url: ScreenId.BOARD}),
          },
        ]}
      />
      <Content>
        <div className={styles.titleRow}>
          <div className={styles.title}>공지사항</div>
          <div className={styles.subTitle}>공지사항입니다.</div>
        </div>
        <div className={styles.pcListContainer}>
          <table>
            <colgroup>
              <col width={80} />
              <col />
              <col width={90} />
              <col width={120} />
              <col width={74} />
            </colgroup>
            <thead>
              <tr>
                <td>번호</td>
                <td>제목</td>
                <td>작성자</td>
                <td>작성일</td>
                <td>조회</td>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).length === 0 ? (
                <tr className={styles.emptyRow}>
                  <td colSpan={5}>검색결과가 없습니다.</td>
                </tr>
              ) : (
                <>
                  {listData?.result.map(item => (
                    <tr key={item.no} onClick={() => goToDetail({data: item})}>
                      <td>{item.no}</td>
                      <td>{item.title}</td>
                      <td>TOV WEDDING</td>
                      <td>
                        {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{(item.view_cnt || 0).toLocaleString()}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.mobileListContainer}>
          {(listData?.result || []).length === 0 ? (
            <div className={[styles.item, styles.emptyRow].join(" ")}>
              검색결과가 없습니다.
            </div>
          ) : (
            <>
              {listData?.result.map(item => (
                <div
                  key={item.no}
                  className={styles.item}
                  onClick={() => goToDetail({data: item})}>
                  <div className={styles.topRow}>{item.title}</div>
                  <div className={styles.bottomRow}>
                    <div>TOV WEDDING</div>
                    <div>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                    <div>조회 {(item.view_cnt || 0).toLocaleString()}</div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.searchContainer}>
          <div className={styles.dateContainer}>
            <SelectBox
              list={[
                {value: "", label: "전체"},
                {value: "7d", label: "일주일"},
                {value: "1m", label: "한달"},
                {value: "3m", label: "세달"},
              ]}
              value={searchData.date}
              singleSelect={value =>
                setSearchData({...searchData, date: value as Datas.TSearchDate})
              }
              boxClassName={styles.select}
              className={styles.selectInput}
            />
          </div>
          <div className={styles.typeContainer}>
            <SelectBox
              list={[
                {value: "title", label: "제목"},
                {value: "content", label: "내용"},
                {value: "writer", label: "글쓴이"},
              ]}
              value={searchData.type}
              singleSelect={value =>
                setSearchData({...searchData, type: value as Datas.TSearchType})
              }
              boxClassName={styles.select}
              className={styles.selectInput}
            />
          </div>
          <div className={styles.textContainer}>
            <InputBox
              value={searchData.searchTxt}
              onValue={value =>
                setSearchData({...searchData, searchTxt: value})
              }
              boxClassName={styles.inputBox}
              focusBoxClassName={styles.inputBoxFocus}
              onEnter={getList}
            />
          </div>
          <div className={styles.btnContainer} onClick={() => getList()}>
            찾기
          </div>
        </div>
      </Content>
    </div>
  );
}
