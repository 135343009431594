import {Images} from "../assets";

export type TWeddingType =
  | "wedding01"
  | "wedding02"
  | "wedding03"
  | "wedding04"
  | "wedding05"
  | "wedding06"
  | "wedding07"
  | "wedding08"
  | "wedding09"
  | "wedding10"
  | "wedding11";

export const weddingData: {
  [key in TWeddingType]: {
    title: string;
    enTitle: string;
    menuTitle?: string;
    bannerImg: string;
    infoImg: string;
    mainImgArr: string[];
    allImgArr: string[];
    info: {title: string; content: string}[];
    googleMap: string;
  };
} = {
  wedding01: {
    enTitle: "Renaissance Ribera Church",
    title: "르네상스 리베라",
    bannerImg: Images.RiberaBanner,
    infoImg: Images.RiberaInfo,
    mainImgArr: [
      Images.Ribera01,
      Images.Ribera03,
      Images.Ribera05,
      Images.Ribera06,
    ],
    allImgArr: [
      Images.Ribera01,
      Images.Ribera02,
      Images.Ribera03,
      Images.Ribera04,
      Images.Ribera05,
      Images.Ribera06,
    ],
    info: [
      {title: "수용인원", content: `약 48명`},
      {title: "공항에서 거리(차편으로)", content: `약 50분`},
      {title: "예식 접수개시", content: `통상적인 제한없음`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어 or 영어`},
      {title: "혼증명서기재 언어", content: `일본어 or 영어 혼재`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `길이 12m / 폭 1.8m 푸른색 바닥`},
      {
        title: "예식 가능시간",
        content: `①10:00 ②11:30 ③13:00 ④14:30\n⑤16:00 ⑥17:30 ⑦19:00`,
      },
      {title: "특징", content: `오키나와 유일 요트 위 웨딩촬영 가능.`},
      {
        title: "주소",
        content: `(3425-2) Yamada, Onna-mura,\nKunigami-gun, Okinawa`,
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.6380425272378!2d127.78544007624166!3d26.43515478044869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e5100aaefd1151%3A0xf4163e96f9947e0a!2s3425-2%20Yamada%20Parking!5e0!3m2!1sko!2skr!4v1723133138168!5m2!1sko!2skr",
  },
  wedding02: {
    enTitle: "Alivilia Glory",
    title: "알리빌라 글로리",
    bannerImg: Images.AliviliaBanner,
    infoImg: Images.Alivilia1,
    mainImgArr: [Images.Alivilia1, Images.Alivilia3, Images.Alivilia4],
    allImgArr: [
      Images.Alivilia1,
      Images.Alivilia2,
      Images.Alivilia3,
      Images.Alivilia4,
      Images.Alivilia5,
      Images.Alivilia6,
    ],
    info: [
      {title: "수용인원", content: "약 100명"},
      {title: "공항에서 거리(차편으로)", content: "약 60분"},
      {title: "예식 접수개시", content: "통상적인 제한없음"},
      {title: "예약 불가일", content: "채플 및 비치특별행사일"},
      {title: "예식 진행언어", content: "일본어 or 영어"},
      {title: "혼증명서기재 언어", content: "일본어 or 영어 혼재"},
      {title: "예식 스타일", content: "크리스트교식 or 무종교식 (옵션)"},
      {
        title: "버진로드",
        content: "길이 14m / 폭 1.8m 대리석(사이드에 황동 라인)",
      },
      {
        title: "예식 가능시간",
        content: "①10:00 ②11:30 ③13:00 ④14:30\n⑤16:00 ⑥17:30 ⑦19:00",
      },
      {title: "특징", content: "아방가르드 분위기의 유럽실 전통 교회예식 가능"},
      {
        title: "주소",
        content:
          "Alivila Glory Church,600 Gima, Yomitan Village, Nakagami-gun, Okinawa",
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.3004047205063!2d127.71321907624116!3d26.413780681352282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e516d7ecef0cbb%3A0x92de143b42371c9c!2sAlivila%20Glory%20Wedding%20Chapel!5e0!3m2!1sko!2skr!4v1723134807163!5m2!1sko!2skr",
  },
  wedding03: {
    enTitle: "Montrey Lumeru",
    title: "몬트레이 루메르",
    bannerImg: Images.banner03,
    infoImg: Images.MontreyInfo,
    mainImgArr: [
      Images.Montrey02,
      Images.Montrey03,
      Images.Montrey05,
      Images.Montrey06,
      Images.Montrey07,
      Images.Montrey08,
    ],
    allImgArr: [
      Images.Montrey01,
      Images.Montrey02,
      Images.Montrey03,
      Images.Montrey04,
      Images.Montrey05,
      Images.Montrey06,
      Images.Montrey07,
      Images.Montrey08,
    ],
    info: [
      {title: "수용인원", content: `약 70명`},
      {title: "공항에서 거리(차편으로)", content: `약 55분`},
      {title: "예식 접수개시", content: `통상적인 제한없음`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어 or 영어`},
      {title: "혼증명서기재 언어", content: `일본어 or 영어 혼재`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `길이 16m / 폭 1.8m 대리석(화이트)`},
      {
        title: "예식 가능시간",
        content: `①10:00 ②11:30 ③13:00 ④14:30\n⑤16:00 ⑥17:30 ⑦19:00`,
      },
      {
        title: "특징",
        content: `오키나와 예식장 중\n버진로드가 가장 길고\n호텔시설이 좋음`,
      },
      {
        title: "주소",
        content: `Monterey Lemaire Church Hotel Monterey Okinawa Spa & Resort\n1635 Fujitsuki, Onna-mura, Kunigami-gun, Okinawa`,
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.062154375253!2d127.8031972762422!3d26.453725379663013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e51acba16e6ac5%3A0x228a3a9cb87b2069!2z7Zi47YWUIOuqrO2GoOugiCDsmKTtgqTrgpjsmYAg7Iqk7YyM77yG66as7KGw7Yq4!5e0!3m2!1sko!2skr!4v1723135088865!5m2!1sko!2skr",
  },
  wedding04: {
    enTitle: "Lazor garden Alivila Chiristia",
    title: "라솔가든 아리빌라 크리스티아",
    bannerImg: Images.banner04,
    infoImg: Images.LazorInfo,
    mainImgArr: [Images.Lazor01, Images.Lazor02, Images.Lazor06],
    allImgArr: [
      Images.LazorInfo,
      Images.Lazor01,
      Images.Lazor02,
      Images.Lazor03,
      Images.Lazor04,
      Images.Lazor05,
      Images.Lazor06,
    ],
    info: [
      {title: "수용인원", content: `약 60명`},
      {title: "공항에서 거리(차편으로)", content: `약 60분`},
      {title: "예식 접수개시", content: `통상적인 제한없음`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어 or 영어`},
      {title: "혼증명서기재 언어", content: `일본어 or 영어 혼재`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `길이 12m / 폭 1.2m / 유리 크리스탈`},
      {
        title: "예식 가능시간",
        content: `①10:30 ②12:00 ③13:30 ④15:00\n⑤16:30 ⑥18:00 ⑦19:30`,
      },
      {
        title: "특징",
        content: `화려한 분위기의 깔끔한 시설과 스냅과\n웨딩촬영에 편리함.`,
      },
      {
        title: "주소",
        content: `Lazor Garden Alivila Christia Church,\n614 Gima, Yomitan Village, Nakagami-gun,\nOkinawa Prefecture`,
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.2678437974896!2d127.71260042624117!3d26.414831781307942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e516d7a04b71e9%3A0x42c099859131bac7!2sLazor%20Garden%20Alivila!5e0!3m2!1sko!2skr!4v1723135470470!5m2!1sko!2skr",
  },
  wedding05: {
    enTitle: "Aquagrace Chapel",
    title: "더 요미탄 리조트 아쿠아 그레이스",
    bannerImg: Images.banner05,
    infoImg: Images.AquagraceInfo,
    mainImgArr: [
      Images.AquagraceInfo,
      Images.Aquagrace01,
      Images.Aquagrace03,
      Images.Aquagrace04,
      Images.Aquagrace05,
    ],
    allImgArr: [
      Images.AquagraceInfo,
      Images.Aquagrace01,
      Images.Aquagrace02,
      Images.Aquagrace03,
      Images.Aquagrace04,
      Images.Aquagrace05,
    ],
    info: [
      {title: "수용인원", content: `약 60명`},
      {title: "공항에서 거리(차편으로)", content: `약 70분`},
      {title: "예식 접수개시", content: `18개월 전부터`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어`},
      {title: "혼증명서기재 언어", content: `영어`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `13m (대리석)`},
      {
        title: "예식 가능시간",
        content: `①9:00 ②10:00 ③11:00 ④12:00\n⑤13:00 ⑥14:00 ⑦15:00 ⑦16:00\n⑦17:00\n*17:00프레임은 조건부로 대응 가능`,
      },
      {
        title: "특징",
        content: `최고급의 분위기와 연회장이 바로 이어져 있어\n사용이 편리함.`,
      },
      {title: "주소", content: `675 Uza, Yomitan, Nakagami District,\nOkinawa`},
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.6896167876157!2d127.7149236762419!3d26.433491080519023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e5173696c331cf%3A0x5cd849c8272dbf3c!2s675%20Uza%2C%20Yomitan%2C%20Nakagami%20District%2C%20Okinawa%20904-0328%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723135655309!5m2!1sko!2skr",
  },
  wedding06: {
    enTitle: "Kouri Island Sky&ocean",
    title: "코우리 하늘과 바다의 웨딩",
    bannerImg: Images.banner06,
    infoImg: Images.Kouri01,
    mainImgArr: [
      Images.Kouri01,
      Images.Kouri02,
      Images.Kouri03,
      Images.Kouri04,
      Images.Kouri07,
    ],
    allImgArr: [
      Images.Kouri01,
      Images.Kouri02,
      Images.Kouri03,
      Images.Kouri04,
      Images.Kouri05,
      Images.Kouri06,
      Images.Kouri07,
    ],
    info: [
      {title: "수용인원", content: `약 40명`},
      {title: "공항에서 거리(차편으로)", content: `약 90분`},
      {title: "예식 접수개시", content: `18개월 전부터`},
      {
        title: "예약 불가일",
        content: `코우리 오션 타워의 특별행사일 코우리지마 매직아워\nRUN in 이마키진촌：예년 4월 제3주 토요일 개최`,
      },
      {title: "예식 진행언어", content: `일본어`},
      {title: "혼증명서기재 언어", content: `영어`},
      {title: "예식 스타일", content: `무종교식`},
      {title: "버진로드", content: `9m(유리)`},
      {
        title: "예식 가능시간",
        content: `①9:30 ②10:30 ③11:30 ④12:30\n⑤13:30 ⑥14:30 ⑦15:30 ⑦16:30\n⑦17:30`,
      },
      {
        title: "특징",
        content: `오키나와 최고의 명소 코우리 대교를 배경으로\n하늘과 바다를 함께 느끼는 전망.`,
      },
      {
        title: "주소",
        content: `588 Kouri, Nakijin, Kunigami District,\nOkinawa`,
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.390797040542!2d128.02090917624759!3d26.699959569199713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e459cba7f0b633%3A0x229d0ae9c6918289!2s588%20Kouri%2C%20Nakijin%2C%20Kunigami%20District%2C%20Okinawa%20905-0406%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723135883862!5m2!1sko!2skr",
  },
  wedding07: {
    enTitle: "Shigira Mirage Bayside Chapel",
    title: "미야코지마 시기라 미라지 베이사이드",
    bannerImg: Images.banner07,
    infoImg: Images.Shigira01,
    mainImgArr: [
      Images.Shigira02,
      Images.Shigira03,
      Images.Shigira04,
      Images.Shigira06,
    ],
    allImgArr: [
      Images.Shigira01,
      Images.Shigira02,
      Images.Shigira03,
      Images.Shigira04,
      Images.Shigira05,
      Images.Shigira06,
    ],
    info: [
      {title: "수용인원", content: `약 50명`},
      {title: "공항에서 거리(차편으로)", content: `미야코공항에서 약 15분`},
      {title: "예식 접수개시", content: `18개월 전부터`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어`},
      {title: "혼증명서기재 언어", content: `영어`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `11m(대리석)`},
      {title: "예식 가능시간", content: `①9:00 ②11:00 ③13:00 ④15:00`},
      {
        title: "특징",
        content: `아름다운 미야코지마 바다를 연상케 하는 시원한 느낌.`,
      },
      {title: "주소", content: `Arazato-1405-106 Ueno, Miyakojima, Okinawa`},
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.188240792567!2d125.33672587620502!3d24.72041695084557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34f4563644f01f83%3A0xb5a153f3b2686687!2sArazato-1405-106%20Ueno%2C%20Miyakojima%2C%20Okinawa%20906-0202%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723136046554!5m2!1sko!2skr",
  },
  wedding08: {
    enTitle: "Allamanda Chapel",
    title: "미야코지마 알라만다",
    bannerImg: Images.banner08,
    infoImg: Images.AllamandaInfo,
    mainImgArr: [
      Images.AllamandaInfo,
      Images.Allamanda01,
      Images.Allamanda02,
      Images.Allamanda03,
      Images.Allamanda04,
      Images.Allamanda05,
    ],
    allImgArr: [
      Images.AllamandaInfo,
      Images.Allamanda01,
      Images.Allamanda02,
      Images.Allamanda03,
      Images.Allamanda04,
      Images.Allamanda05,
    ],
    info: [
      {title: "수용인원", content: `약 40명`},
      {title: "공항에서 거리(차편으로)", content: `미야코공항에서 약 15분`},
      {title: "예식 접수개시", content: `18개월 전부터`},
      {title: "예약 불가일", content: `채플 및 비치특별행사일`},
      {title: "예식 진행언어", content: `일본어`},
      {title: "혼증명서기재 언어", content: `영어`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `10m(대리석)`},
      {title: "예식 가능시간", content: `①10:00 ②12:00 ③14:00 ④16:00`},
      {
        title: "특징",
        content: `미야코지마 섬의 아름다움과 유럽 성당분위기의 프라이빗한 장소.`,
      },
      {title: "주소", content: `Arazato-926-25 Ueno, Miyakojima, Okinawa`},
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.188240792567!2d125.33672587620502!3d24.72041695084557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34f45637e5f8f531%3A0x111406bd9f153586!2sArazato-926-25%20Ueno%2C%20Miyakojima%2C%20Okinawa%20906-0203%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723136091774!5m2!1sko!2skr",
  },
  wedding09: {
    menuTitle: "Blessed By The Sea",
    enTitle: "TOV WEDDING",
    title: "Blessed By The Sea",
    bannerImg: Images.banner09,
    infoImg: Images.BlessedInfo,
    mainImgArr: [
      Images.BlessedInfo,
      Images.Blessed01,
      Images.Blessed02,
      Images.Blessed03,
      Images.Blessed04,
    ],
    allImgArr: [
      Images.BlessedInfo,
      Images.Blessed01,
      Images.Blessed02,
      Images.Blessed03,
      Images.Blessed04,
    ],
    info: [
      {title: "수용인원", content: `약 46명`},
      {title: "공항에서 거리(차편으로)", content: `약 70분`},
      {title: "예식 접수개시", content: `40일전 마감`},
      {title: "예약 불가일", content: `연말연시 (24년12월29일-25년 1월 7일)`},
      {title: "예식 진행언어", content: `일본어 or 영어`},
      {title: "혼증명서기재 언어", content: `일본어`},
      {title: "예식 스타일", content: `크리스트교식 or 무종교식 (옵션)`},
      {title: "버진로드", content: `10m`},
      {title: "예식 가능시간", content: `①10:00 ②16:30`},
      {
        title: "특징",
        content: `연회장 공간이 가장 넓어서 많은 인원 수용이 가능.`,
      },
      {title: "주소", content: `1490-1, Kise, Nago-City, Okinawa`},
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3569.6897236999357!2d127.93064377624384!3d26.53010167642707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e501b59c3ef029%3A0x5b0e0f457d6e4cc7!2zMTQ5MC0xIEtpc2UsIE5hZ28sIE9raW5hd2EgOTA1LTAwMjYg7J2867O4!5e0!3m2!1sko!2skr!4v1723136251305!5m2!1sko!2skr",
  },
  wedding10: {
    enTitle: "Churaumi Wedding",
    title: "츄라우미 웨딩",
    bannerImg: Images.ChuraumiBanner,
    infoImg: Images.Churaumi01,
    mainImgArr: [
      Images.Churaumi01,
      Images.Churaumi02,
      Images.Churaumi03,
      Images.Churaumi04,
      Images.Churaumi05,
      Images.Churaumi06,
      Images.Churaumi07,
    ],
    allImgArr: [
      Images.Churaumi01,
      Images.Churaumi02,
      Images.Churaumi03,
      Images.Churaumi04,
      Images.Churaumi05,
      Images.Churaumi06,
      Images.Churaumi07,
    ],
    info: [
      {
        title: "촬영장소 (촬영스팟 2곳)",
        content: `추라우미 수족관 '쿠로시오 바다'\n열대드림센터 또는 에메랄드해변`,
      },
      {
        title: "의상",
        content: `신랑신부의상（드레스＆턱시도 각한벌）\n신부헤어메이크＆헬퍼 포함`,
      },
      {
        title: "촬영",
        content: "사진촬영、촬영사진 400장、앨범제작 20페이지 구성",
      },
      {
        title: "예식",
        content:
          "사회자및 예식진행、플라워 아치、버진로드、예식장 세팅、기타 데코레이션",
      },
      {title: "옵션", content: "사전피팅가능"},
      {
        title: "기타",
        content: `※신청전 사전상담신청을 부탁드립니다.\n・어린이의상、류큐전통의상 서비스 이용가능`,
      },
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.6315571693144!2d127.78626077624176!3d26.43536398043989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e5100aa02197af%3A0xb17edfb71eea39d2!2s3425-2%20Yamada%2C%20Onna%2C%20Kunigami%20District%2C%20Okinawa%20904-0416%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723134994923!5m2!1sko!2skr",
  },
  wedding11: {
    enTitle: "Mihana",
    title: "미하나",
    bannerImg: Images.MihanaBanner,
    infoImg: Images.MihanaInfo,
    mainImgArr: [
      Images.Mihana02,
      Images.Mihana03,
      Images.Mihana04,
      Images.Mihana05,
      Images.Mihana06,
    ],
    allImgArr: [
      Images.Mihana01,
      Images.Mihana02,
      Images.Mihana03,
      Images.Mihana04,
      Images.Mihana05,
      Images.Mihana06,
    ],
    info: [
      {title: "수용인원", content: `상담`},
      {title: "공항에서 거리(차편으로)", content: `약 60분`},
      {title: "예식 접수개시", content: `상담`},
      {title: "예약 불가일", content: `상담`},
      {title: "예식 진행언어", content: `일본어`},
      {title: "혼증명서기재 언어", content: `X`},
      {
        title: "예식 스타일",
        content: `리조트 내에서 free style 결혼식/옥상채플/브라이즈룸/레스토랑/대기실/살롱/탈의실있음/30인까지 숙박가능(5동) (일부객실)`,
      },
      {
        title: "시설",
        content: `빌라, 야외풀장 , 바비큐시설, 캐쥬얼파티, Free drinks 제공, 웨딩촬영 드레스 , 헤어메이크업 포함, 사진 100장 촬영`,
      },
      {
        title: "예식 가능시간",
        content: `1일 1커플 한정 / 시간에 대해서는 상담`,
      },
      {title: "연회장 정보", content: `연회장 수용가능 인원 : 58명`},
      {
        title: "특징",
        content: `유명 셰프의 요리를 코스요리로 여유롭게 즐길 수 있음.`,
      },
      {title: "주소", content: `890-1 Maeda, Onna-son, Kunigami-gun, Okinawa`},
    ],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.5227405167398!2d127.76576117624181!3d26.43887388029158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e5107e52184f01%3A0x534728380615fc3d!2s890-1%20Maeda%2C%20Onna%2C%20Kunigami%20District%2C%20Okinawa%20904-0417%20%EC%9D%BC%EB%B3%B8!5e0!3m2!1sko!2skr!4v1723468268528!5m2!1sko!2skr",
  },
};

const MENU_INDEX: TWeddingType[] = [
  "wedding02",
  "wedding04",
  "wedding03",
  "wedding05",
  "wedding06",
  "wedding01",
  "wedding07",
  "wedding08",
  "wedding09",
  "wedding10",
  "wedding11",
];

export const TOV_WEDDING_MENU: {
  title: string;
  type: TWeddingType;
}[] = MENU_INDEX.map(type => {
  const data = weddingData[type];
  return {type, title: data.menuTitle || data.enTitle};
});
