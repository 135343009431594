import {
  CLSMetric,
  FIDMetric,
  FCPMetric,
  LCPMetric,
  TTFBMetric,
} from "web-vitals";

type ReportHandler = (
  metric: CLSMetric | FIDMetric | FCPMetric | LCPMetric | TTFBMetric,
) => void;

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({onCLS, onFID, onFCP, onLCP, onTTFB}) => {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
