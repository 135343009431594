import React from "react";
import styles from "./style.module.scss";
import {Content, Img, TopSection} from "@components";
import {Images, ScreenId} from "@assets";
import {useNav} from "@hook";

export default function MapPage() {
  const {navigate} = useNav();

  return (
    <div className={styles.container}>
      <TopSection
        title="RESERVATION"
        subTitle="찾아오는 길"
        imgUrl={Images.home_04}
        menuList={[
          {
            title: "상담예약",
            onClick: () => navigate({url: ScreenId.RESERVATION}),
          },
          {title: "찾아오는 길", onClick: () => navigate({url: ScreenId.MAP})},
        ]}
      />
      <Content>
        <div className={styles.logoSection}>
          <Img src={Images.logoGray} />
          <div className={styles.title}>찾아오는 길</div>
          <div className={styles.subTitle}>
            서울특별시 서초구 서초대로 74길 45, B1층 1044호(서초동, ENDEAVOR
            TOWER)
          </div>
        </div>
        <div className={styles.mapSection}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.6046903834617!2d127.02550676763494!3d37.49365394184898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca15ab8da8905%3A0x4d44880fd5894283!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDshJzstIjrjIDroZw3NOq4uCA0NQ!5e0!3m2!1sko!2skr!4v1723124373673!5m2!1sko!2skr"
            width="100%"
            height="450"
            frameBorder={0}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </Content>
    </div>
  );
}
