import React, {CSSProperties} from "react";
import Select, {ActionMeta, MultiValue, SingleValue} from "react-select";
import styles from "./style.module.scss";

interface IProps {
  disabled?: boolean;
  multiple?: boolean;
  value?: string[] | string;
  list: {
    value: string;
    label: string;
  }[];
  boxStyle?: CSSProperties;
  singleSelect?: (data: string) => void;
  multipleSelect?: (data: string[]) => void;
  placeholder?: string;
  className?: string;
  boxClassName?: string;
}

interface ISingleProps extends IProps {
  multiple?: false;
  singleSelect: (data: string) => void;
}

interface IMultipleProps extends IProps {
  multiple: true;
  multipleSelect: (data: string[]) => void;
}

export default function SelectBox({
  disabled,
  list,
  value,
  boxStyle = {},
  singleSelect,
  multipleSelect,
  multiple = false,
  placeholder,
  className,
  boxClassName,
}: ISingleProps | IMultipleProps) {
  const defaultValue = list.filter(item => {
    if (typeof value === "object" && Array.isArray(value)) {
      return value.includes(item.value);
    } else {
      return item.value === value;
    }
  });

  function onChangeHandler(
    data:
      | SingleValue<{
          value: string;
          label: string;
        }>
      | MultiValue<{
          value: string;
          label: string;
        }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>,
  ) {
    if (multiple) {
      const value = data as MultiValue<{
        value: string;
        label: string;
      }>;
      multipleSelect?.((value || []).map(item => item.value));
    } else {
      const value = data as SingleValue<{
        value: string;
        label: string;
      }>;
      singleSelect?.(value?.value || "");
    }
  }

  return (
    <div className={[styles.box, boxClassName].join(" ")} style={{...boxStyle}}>
      <Select
        isDisabled={disabled}
        className={[styles.select, className].join(" ")}
        classNames={{
          control: state =>
            [styles.control, state.isFocused && styles.focus].join(" "),
          indicatorsContainer: () => styles.indicatorsContainer,
        }}
        isMulti={multiple}
        isClearable={!!multiple}
        onChange={onChangeHandler}
        options={list}
        menuPosition="fixed"
        menuPlacement="auto"
        placeholder={placeholder || "선택"}
        defaultValue={defaultValue}
        value={defaultValue}
      />
    </div>
  );
}
