import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {utils} from "@utils";

interface IProps {
  visible?: boolean;
  close?: () => void;
  backOpacity?: number;
  backDropColor?: string;
  children: JSX.Element;
  isFullModal?: boolean;
  isCenter?: boolean;
  toWay?: "bottom" | "top" | "right" | "left" | "none";
  className?: string | string[];
  containerClassName?: string | string[];
  position?: {
    bottom?: number;
    top?: number;
    right?: number;
    left?: number;
  };
}

export default function Modal({
  visible = false,
  close,
  backDropColor = "#000000",
  backOpacity = 40,
  children,
  isFullModal = false,
  isCenter = false,
  toWay = "top",
  className,
  containerClassName,
  position = {},
}: IProps) {
  const classList = [
    styles.img,
    ...(Array.isArray(className) ? className : [className]),
  ].filter(utils.isDefined);
  const containerClassList = [
    ...(Array.isArray(containerClassName)
      ? containerClassName
      : [containerClassName]),
  ].filter(utils.isDefined);

  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsClosing(false);
      setIsVisible(true);
    } else {
      setIsClosing(true);
    }
  }, [visible]);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [isClosing]);

  if (!isVisible) {
    return <></>;
  }

  return (
    <div
      className={[styles.slideModal, ...classList].join(" ")}
      style={{
        backgroundColor: isFullModal
          ? backDropColor
          : `${String(backDropColor)}${backOpacity}`,
      }}>
      <div
        className={[
          styles.wrap,
          isClosing && styles.isClosing,
          !["none"].includes(toWay) && styles[`way-${toWay}`],
        ].join(" ")}>
        <div className={[styles.container, containerClassList].join(" ")}>
          <div className={styles.background} onClick={close} />
          <div
            className={[
              styles.content,
              isFullModal && styles.isFull,
              isCenter && styles.isCenter,
            ].join(" ")}
            style={{
              ...(!isNaN(Number(position.bottom)) && {bottom: position.bottom}),
              ...(!isNaN(Number(position.top)) && {top: position.top}),
              ...(!isNaN(Number(position.right)) && {right: position.right}),
              ...(!isNaN(Number(position.left)) && {left: position.left}),
            }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
