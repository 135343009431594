import React, {useMemo} from "react";
import styles from "./style.module.scss";
import {useNav} from "@hook";
import {Images, ScreenId, Weddings} from "@assets";
import {Content, Img, TopSection} from "@components";
import {utils} from "@utils";
import Slider from "react-slick";

export default function WeddingPage() {
  const {getParams, navigate} = useNav();
  const {type} = getParams<{type: Weddings.TWeddingType}>();
  const data = useMemo(() => {
    return Weddings.weddingData[type];
  }, [type]);

  return (
    <div className={styles.container}>
      <TopSection
        title={data.enTitle}
        subTitle={data.title}
        imgUrl={data.bannerImg}
        menuList={Weddings.TOV_WEDDING_MENU.map(item => ({
          title: item.title,
          onClick: () =>
            navigate({url: ScreenId.WEDDING, params: {type: item.type}}),
        }))}
      />
      <Content>
        <div className={styles.logoSection}>
          <Img src={Images.logoGray} />
          <div className={styles.title}>{data.title}</div>
          <div className={styles.subTitle}>{data.enTitle}</div>
        </div>
        <div className={styles.weddingContainer}>
          <div className={styles.mainImgContainer}>
            <Slider
              autoplay
              autoplaySpeed={1500}
              dots={true}
              dotsClass={styles.bannerPaging}
              customPaging={() => <div className={styles.dots} />}>
              {data.mainImgArr.map((img, index) => (
                <Img key={index} src={img} />
              ))}
            </Slider>
          </div>
          <div className={styles.galleryInfoContainer}>
            <div className={styles.infoSection}>
              <div className={styles.infoTitle}>INFORMATION</div>
              <div className={styles.infoContent}>
                {(data.info || []).map((item, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.itemTitle}>{item.title}</div>
                    <div>{item.content}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.gallery}>
              <div className={styles.selectedGallery}>
                <Img src={data.infoImg} />
              </div>
            </div>
          </div>
          <div className={styles.mapContainer}>
            <iframe
              src={data.googleMap}
              width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className={styles.reservationSection}>
            <div className={styles.title}>웨딩 예약 및 문의</div>
            <div className={styles.subTitle}>Reservations & Inquiries</div>
            <div className={styles.btn} onClick={utils.openKakao}>
              <Img src={Images.calendar} />
              <span>웨딩 예약 및 문의</span>
            </div>
          </div>
          <div className={styles.gallerySection}>
            <div className={styles.title}>
              <div className={styles.pageTitle}>{data.title}</div> 갤러리
            </div>
            <div className={styles.subTitle}>TOV WEDDING GALLERY</div>
            <div className={styles.div} />
            <div className={styles.listContainer}>
              {[
                ...data.allImgArr,
                ...new Array(
                  data.allImgArr.length % 4 == 0
                    ? 0
                    : 4 - (data.allImgArr.length % 4),
                ),
              ].map((imgUrl, index) => (
                <div
                  key={index}
                  className={[styles.imgItem, !imgUrl && styles.empty].join(
                    " ",
                  )}>
                  <Img src={imgUrl} isDetailModal={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}
