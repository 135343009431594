import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {Header, Footer} from "@components";

interface Props {
  children: JSX.Element;
}

export default function Wrapper({children}: Props) {
  const [isApp, setIsApp] = useState(false);

  useEffect(() => {
    setIsApp(window.innerWidth < 1024);
  }, [window.innerWidth]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.main}>{children}</div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}
