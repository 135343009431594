import React from "react";
import {WebRouteApp} from "@navigation";
import {Route, Routes} from "react-router-dom";

export default function WebNavigation() {
  return (
    <Routes>
      <Route path="/*" element={<WebRouteApp />} />
    </Routes>
  );
}
