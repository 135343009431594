import React from "react";
import styles from "./style.module.scss";
import {Content, Img, TopSection} from "@components";
import {Images, ScreenId} from "@assets";
import _ from "lodash";
import {useNav} from "@hook";

export default function CompanyPage() {
  const {navigate} = useNav();

  return (
    <div className={styles.container}>
      <TopSection
        title="ABOUT"
        subTitle="회사소개"
        imgUrl={Images.home_05}
        menuList={[
          {
            title: "토브웨딩스토리",
            onClick: () => navigate({url: ScreenId.STORY}),
          },
          {title: "회사소개", onClick: () => navigate({url: ScreenId.COMPANY})},
        ]}
      />
      <Content>
        <div className={styles.logoSection}>
          <Img src={Images.logoGray} />
        </div>
        <div className={styles.listContainer}>
          <div className={styles.item}>
            전형적인 고비용의 공장형 예식 스타일을 벗어나, 특별한 결혼 스타일을
            추구하는 20-30대의 수요가 늘어나고 있습니다.
            <br />
            특히, 2020년 글로벌 팬데믹 이후 이러한 젊은 세대층의 수요는 주변에서
            더욱 뚜렷해지고 있습니다.
          </div>
          <div className={styles.item}>
            하지만, 이러한 수요를 충족시킬 수 있는 합리적인 가격의 아름다운
            예식장은 턱없이 부족한 상황이며, 그 수요를 맞추지 못하고 있습니다.
            <br />
            이에, 고귀한 순간을 꿈꾸는 예비 신랑, 신부님께 남들과는 다른
            특별하고 환상적인 경험을 오키나와에서 선사해 드리고자 합니다.
          </div>
          <div className={styles.item}>
            저 또한 한국의 전형적인 예식 스타일을 벗어나, 오키나와에서 직접
            결혼을 했습니다. 그 경험은 평생 잊지 못할 특별한 기억으로 남아
            있습니다.
          </div>
          <div className={styles.item}>
            그러나 외국 업체와 결혼을 준비하는 과정에서 불편함을 느꼈고, 이로
            인한 불안함은 크고 작은 문제를 야기했습니다.
            <br />
            의사소통의 어려움 또한 큰 장벽이 되어 순탄치 않은 예식 준비가 되었던
            것은 사실이었습니다.
            <br />이 경험을 토대로 일본 오키나와 관련 비즈니스를 확대하여 Tov
            Wedding을 설립하게 되었습니다.
          </div>
          <div className={styles.item}>
            Tov Wedding은 ORWA의 한국 정식 에이전시로 선임되어, 오키나와
            예식장을 전문적으로 다루며
            <br />
            한국 고객에게 최적의 서비스를 제공하기 위한 모든 시스템을 갖추고
            있습니다.
            <br />
            TOV를 찾아주시는 예비 신랑 신부님들께는 편안하고 신뢰 있는 서비스를
            제공하며,
            <br />
            기존 한국의 전형적인 예식장보다 믿기 힘든 합리적인 가격과 높은
            퀄리티로 감동을 선사할 것을 약속드립니다.
          </div>
          <div className={styles.owner}>TOV 대표이사 이 준혁</div>
        </div>
      </Content>
    </div>
  );
}
