import React, {useRef} from "react";
import styles from "./style.module.scss";
import {Content, Img, TopSection} from "@components";
import {Images, ScreenId} from "@assets";
import _ from "lodash";
import {useNav} from "@hook";

export default function CompanyPage() {
  const {navigate} = useNav();
  const containerWidth = useRef(0).current;

  const windowWidth = window.innerWidth;
  const youtubeHeight = Math.min(
    Math.round(containerWidth * (315 / 560)),
    windowWidth,
  );

  return (
    <div className={styles.container}>
      <TopSection
        title="ABOUT"
        subTitle="토브웨딩 스토리"
        imgUrl={Images.home_05}
        menuList={[
          {
            title: "토브웨딩스토리",
            onClick: () => navigate({url: ScreenId.STORY}),
          },
          {title: "회사소개", onClick: () => navigate({url: ScreenId.COMPANY})},
        ]}
      />
      <Content containerClassName={styles.tovContent}>
        <div className={styles.logoSection}>
          <Img src={Images.logoGray} />
          <div className={styles.title}>TOV WEDDING STORY</div>
        </div>
        <div className={styles.contentSection}>
          <div className={styles.gallery}>
            <div className={styles.infoSection}>
              <div className={styles.title}>
                What is different about
                <br />
                TOV Wedding
              </div>
              <div className={styles.div} />
              <div className={styles.infoContent}>
                <div className={styles.infoItem}>
                  TOV는 오키나와 해외웨딩 전문 제공업체
                  <br />* 국내유일 Okinawa Resort Wedding Association 정식 등록
                  에이전시
                  <br />
                  (ORWA : 현지 웨딩상품 주관기관)로서, 해외 예식장 소개뿐만이
                  아닌
                  <br />
                  국내 오프라인 상담, 현지 TOV 소속 직원의 서포트를 포함한
                  <br />
                  종합적인 웨딩 서비스를 제공합니다.
                </div>
                <div className={styles.infoItem}>
                  웨딩 상담부터 예식이 끝날 때까지 소품, 의상, 헤어 메이크업 등
                  <br />
                  모든 과정을 밀착 지원하는 편리한 플랜을 갖추고 있습니다.
                </div>
                <div className={styles.infoItem}>
                  허니문과 하객들에게 제공할 수 있는 관광, 해양 액티비티, 골프
                  등<br />
                  맞춤 서비스를 연계•제공하는 고품격 웨딩 에이전시입니다.
                </div>
              </div>
              <div
                className={styles.btnContainer}
                onClick={() =>
                  navigate({url: ScreenId.WEDDING, params: {type: "wedding04"}})
                }>
                <button>MORE VIEW</button>
              </div>
            </div>
            <div className={styles.gallerySection}>
              <div id="selected-gallery">
                <Img src={Images.home_01} />
              </div>
            </div>
          </div>
          <div className={styles.youtube}>
            <iframe
              src="https://www.youtube.com/embed/lATxj-h9e7Y?si=_UypjbHCZIiZYjn_"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen></iframe>
          </div>
        </div>
      </Content>
    </div>
  );
}
