import React from "react";
import styles from "./style.module.scss";
import {Img} from "@components";

interface IProps {
  title: string;
  subTitle?: string;
  menuList?: {title: string; onClick?: () => void}[];
  imgUrl?: string;
}

export default function TopSection({
  title,
  subTitle,
  menuList = [],
  imgUrl,
}: IProps) {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Img src={imgUrl} />
        <div className={styles.background}></div>
        <div className={styles.textContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{subTitle}</div>
        </div>
      </div>
      {menuList.length > 0 && (
        <div className={styles.navSection}>
          <div className={styles.content}>
            {menuList.map((item, index) => (
              <div
                key={index}
                className={styles.navItem}
                onClick={item.onClick}>
                {item.title}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
