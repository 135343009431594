import React from "react";
import styles from "./style.module.scss";
import {utils} from "@utils";

interface IProps {
  containerClassName?: string | string[];
  children: React.ReactNode;
}

export default function Content({children, containerClassName}: IProps) {
  const containerClassList = [
    ...(Array.isArray(containerClassName)
      ? containerClassName
      : [containerClassName]),
  ].filter(utils.isDefined);

  return (
    <div className={[styles.container, ...containerClassList].join(" ")}>
      {children}
    </div>
  );
}
