import React, {useState} from "react";
import styles from "./style.module.scss";
import {Img, Modal} from "@components";
import {Images, ScreenId, Weddings} from "@assets";
import {constant} from "@utils";
import {useNav} from "@hook";
import {INav} from "hook/useNav";

const MENU: {
  title: string;
  navParams: INav;
  subMenu: {title: string; navParams: INav}[];
}[] = [
  {
    title: "ABOUT",
    navParams: {url: ScreenId.STORY},
    subMenu: [
      {title: "회사소개", navParams: {url: ScreenId.COMPANY}},
      {title: "토브웨딩 스토리", navParams: {url: ScreenId.STORY}},
    ],
  },
  {
    title: "TOV WEDDING",
    navParams: {url: ScreenId.WEDDING, params: {type: "wedding04"}},
    subMenu: Weddings.TOV_WEDDING_MENU.map(({title, type}) => ({
      title: `- ${title}`,
      navParams: {url: ScreenId.WEDDING, params: {type}},
    })),
  },
  {
    title: "RESERVATION",
    navParams: {url: ScreenId.RESERVATION},
    subMenu: [
      {title: "상담예약", navParams: {url: ScreenId.RESERVATION}},
      {title: "찾아오는 길", navParams: {url: ScreenId.MAP}},
    ],
  },
  {
    title: "COMMUNITY",
    navParams: {url: ScreenId.BOARD},
    subMenu: [{title: "토브웨딩 소식", navParams: {url: ScreenId.BOARD}}],
  },
];

export default function Header() {
  const {navigate} = useNav();
  const [isOpen, setIsOpen] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const isMobile = window.innerWidth < constant.MOBILE_WIDTH;
  const isHome = window.location.pathname === ScreenId.HOME;

  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  function closeSideMenu() {
    setIsSideMenuOpen(false);
  }

  return (
    <>
      <div
        className={[
          styles.container,
          isHome && styles.isHome,
          isOpen && !isMobile && styles.menuOpen,
        ].join(" ")}
        onMouseOver={() => {
          setIsOpen(true);
        }}
        onMouseOut={() => {
          setIsOpen(false);
        }}>
        <div className={styles.content}>
          <div className={styles.menuIcon} onClick={toggleSideMenu}>
            <Img src={Images.menuIconWhite} />
          </div>
          <div
            className={styles.logoContainer}
            onClick={() => navigate({url: ScreenId.HOME})}>
            <Img
              className={[styles.pcLogo, styles.black].join(" ")}
              src={Images.logoRightBlack1}
            />
            <Img
              className={[styles.pcLogo, styles.white].join(" ")}
              src={Images.logoRightWhite1}
            />
            <Img className={styles.mobileLogo} src={Images.logoRightWhite1} />
          </div>
          <div className={styles.menuContainer}>
            <Menu />
          </div>
        </div>
      </div>
      {!!isMobile && (
        <SideMenu visible={isSideMenuOpen} close={closeSideMenu} />
      )}
    </>
  );
}

function Menu() {
  const {navigate} = useNav();

  return (
    <div className={styles.menuContent}>
      {MENU.map(item => (
        <div className={styles.item} key={item.title}>
          <div
            className={styles.title}
            onClick={() => navigate(item.navParams)}>
            {item.title}
          </div>
          <div className={styles.toggleMenu}>
            {item.subMenu.map(subItem => (
              <div
                key={subItem.title}
                className={styles.toggleItem}
                onClick={() => navigate(subItem.navParams)}>
                {subItem.title}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function SideMenu({visible, close}: {visible: boolean; close?: () => void}) {
  const {navigate} = useNav();
  const [toggle, setToggle] = useState<boolean[]>([
    ...new Array(MENU.length).fill(false),
  ]);

  function goToPage(navParams: INav) {
    navigate(navParams);
    close?.();
    setTimeout(() => {
      setToggle([...toggle.map(() => false)]);
    }, 500);
  }

  return (
    <Modal
      visible={visible}
      close={close}
      backOpacity={70}
      toWay="left"
      containerClassName={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.logo}>
          <div className={styles.close} onClick={close}>
            X
          </div>
          <Img src={Images.logoBottomBlack} className={styles.logoImg} />
        </div>
        <div className={styles.menuList}>
          {MENU.map((item, index) => {
            const isOpen = toggle[index];
            return (
              <div className={styles.menuItem} key={item.title}>
                <div className={styles.titleRow}>
                  <div
                    className={styles.title}
                    onClick={() => goToPage(item.navParams)}>
                    {item.title}
                  </div>
                  <div
                    className={styles.toggleBtn}
                    onClick={() =>
                      setToggle([
                        ...toggle.map((_temp, _index) =>
                          index === _index ? !_temp : _temp,
                        ),
                      ])
                    }>
                    {isOpen ? "-" : "+"}
                  </div>
                </div>
                {!!isOpen && (
                  <div className={styles.toggleMenu}>
                    {item.subMenu.map(subItem => (
                      <div
                        key={subItem.title}
                        className={styles.toggleItem}
                        onClick={() => goToPage(subItem.navParams)}>
                        {subItem.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
          <div className={styles.line} />
        </div>
      </div>
    </Modal>
  );
}
