import * as Datas from "@data";
import {axiosPostUtil} from "./axiosUtil";

const api = {
  v1: {
    notice: {
      getList: async (params: {
        searchTxt?: string;
        date?: Datas.TSearchDate;
        type?: Datas.TSearchType;
        limit?: number;
        page?: number;
      }) => {
        return (await axiosPostUtil)<
          Datas.ApiResponse<Datas.ResultPagingResponse<Datas.INotice[]>>
        >("/api/v1/notice/getList", params).then(value => value.data);
      },

      getDetail: async (params: {no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.INotice>>(
          "/api/v1/notice/getDetail",
          params,
        ).then(value => value.data);
      },
    },
  },
};

export default api;
