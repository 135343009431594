import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import mainStyles from "../BoardPage/style.module.scss";
import {useNav} from "@hook";
import {Images, ScreenId} from "@assets";
import * as Datas from "@data";
import {constant, api} from "@utils";
import {Content, Img, TopSection} from "@components";
import moment from "moment";

export default function BoardDetailPage() {
  const {getParams, navigate} = useNav();
  const {no, data} = getParams<{
    no: number;
    data?: Datas.INotice;
  }>();
  const [detail, setDetail] = useState<Datas.INotice | undefined>(data);

  async function getDetail() {
    try {
      const {success, data} = await api.v1.notice.getDetail({no});
      if (success) {
        setDetail(data);
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  function goToList() {
    navigate({url: ScreenId.BOARD, scrollToTop: false});
  }

  useEffect(() => {
    getDetail();
  }, [no]);

  return (
    <div className={styles.container}>
      <TopSection
        title="TOV WEDDING"
        subTitle="토브웨딩 소식"
        imgUrl={Images.home_06}
        menuList={[
          {
            title: "토브웨딩 소식",
            onClick: () => navigate({url: ScreenId.BOARD}),
          },
        ]}
      />
      <Content>
        <div className={styles.titleRow}>
          <div className={styles.title}>공지사항</div>
          <div className={styles.subTitle}>공지사항입니다.</div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentTitleRow}>
            <div className={styles.title}>{detail?.title}</div>
            <div className={styles.subTitleRow}>
              <div className={styles.name}>TOV WEDDING</div>
              <div className={styles.etc}>
                <div className={styles.date}>
                  {moment(detail?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </div>
                <div className={styles.viewCnt}>
                  조회 {Number(detail?.view_cnt || 0).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>{detail?.content}</div>
        </div>
        <div className={styles.btnContainer}>
          <div className={styles.btn} onClick={goToList}>
            목록
          </div>
        </div>
      </Content>
    </div>
  );
}
