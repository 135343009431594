import React from "react";
import {WebNavigation} from "@navigation";
import {Wrapper} from "@components";

export default function RootNavigation() {
  return (
    <Wrapper>
      <WebNavigation />
    </Wrapper>
  );
}
